
#service_container{
    width: 100vw;
    height: 130vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8rem; 
}

#service_container > h3 {
    font-size: 40px;
    height: 10vh;
    font-weight: 700;
    color: #FFFFFFF2;
    line-height: 55px;
    margin-top: 30px;
    /* background-color: red; */
}

.offer-tiles{
    width: 100%;
    height: 80%;
    display: flex;
    justify-content:center;
    flex-wrap: wrap;
    gap: 5rem;
    padding: 25px;
    align-items: center;

}


/* Responsive Design */


/* Desktop and large screens */
@media screen and (min-width: 1025px) and (max-width: 1200px) {
    #service_container{
        width: 100vw;
        height: 130vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8rem;
        background-color: #1b1a1c;
    }
    
    #service_container > h3 {
        font-size: 40px;
        height: 10vh;
        font-weight: 700;
        color: #FFFFFFF2;
        line-height: 55px;
        margin-top: 30px;
        /* background-color: red; */
    }
    
    .offer-tiles{
        width: 100%;
        height: 75%;
        display: flex;
        justify-content:center;
        flex-wrap: wrap;
        gap: 5rem;
        padding: 25px;
        align-items: center;
    
    }
}

/* For Small screens , laptops */
@media screen and (min-width: 769px) and (max-width: 1024px) {
    #service_container{
        width: 100vw;
        height: 130vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* gap: 8rem; */
        background-color: #1b1a1c;
        gap: 1rem;
    
        
      
    }
    
    #service_container > h3 {
        font-size: 40px;
        height: 8%;
        font-weight: 700;
        color: #FFFFFFF2;
        line-height: 55px;
        margin-top: 30px;
        /* background-color: red; */
    }
    
    .offer-tiles{
        
        width: 100%;
        height: 90%;
        display: flex;
        justify-content:center;
        flex-wrap: wrap;
        gap: 5rem;
        align-items: center;
        /* background-color: red; */
        overflow: hidden;
        padding: 35px;

    
    }


}

/* For iPads, Tablets  */
@media screen and (min-width: 481px) and (max-width: 769px) {
        #service_container{
            width: 100%;
            height: 120vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            /* gap: 8rem; */
            background-color: #1b1a1c;
            gap: 1rem;
        
            
          
        }
        
        #service_container > h3 {
            font-size: 27px;
            /* height: 8%; */
            font-weight: 700;
            color: #FFFFFFF2;
            line-height: 55px;
            margin-top: 30px;
            /* background-color: red; */
        }
        
        .offer-tiles{
            width: 100%;
            height: 90%;
            display: flex;
            flex-direction: column;
            justify-content:center;
            flex-wrap: nowrap;
            gap: 2rem;
            align-items: center;
            /* background-color: red; */
            /* overflow: hidden; */
            padding: 35px;
    
        
        }

}



/*  Mobile devices */
@media screen and (min-width: 320px) and (max-width: 480px) {
    #service_container{
        width: 100%;
        height: 125vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* gap: 8rem; */
        background-color: #1b1a1c;
        gap: 1rem;
    
        
      
    }
    
    #service_container > h3 {
        font-size: 20px;
        /* height: 8%; */
        font-weight: 700;
        color: #FFFFFFF2;
        line-height: 55px;
        margin-top: 30px;
        text-align: center;
        /* background-color: red; */
    }
    
    .offer-tiles{
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content:center;
        flex-wrap: nowrap;
        gap: 2rem;
        align-items: center;
        /* background-color: red; */
        /* overflow: hidden; */
        padding: 35px;

    
    }
}