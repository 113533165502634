#about{
    width: 100vw;
    height: 100vh;
    background: linear-gradient(45deg,#5454D41D 90.34%, #5454D445 1000%, #19191B);
    background-color: #19191B;
}


section{
    width: 100%;
    height: 50vh;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 100px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    /* background-color: red; */
}

.section1{
    width: 100%;
    display: flex;
 
}

.section1_content{
    width: 70%;
}
.section1_content > h1{
      font-size: 60px;
      font-weight: 600;
      line-height: 81px;
      color: #FFFFFFF2;
}
.section1_content > p{
      font-size: 19px;
      font-weight: 400;
      line-height: 35px;
      color: #FFFFFFF2;
}
.section1_content > hr{
    width: 55%;

}

.section1 > img{
    width: 30%;
    height: 90%;
}


.section2{
    width: 100%;
}
.section2 > p{
    font-size: 19px;
    font-weight: 400;
    line-height: 35px;
    color: #FFFFFFF2;
}


.section3{
    width: 100%;
    display: flex;
}
.section3 > img{
    width: 30%;
    height: 90%;
}
.section3 > p{
    width: 70%;
    font-size: 19px;
    font-weight: 400;
    line-height: 35px;
    color: #FFFFFFF2;
}

.section4{
    width: 100%;
    height: 30vh;
    font-size: 19px;
    font-weight: 400;
    line-height: 35px;
    color: #FFFFFFF2;
    /* background-color: #19191B; */
}