*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;

}

main{
    width: 100vw;
    height: 100vh;
    background: linear-gradient(45deg ,#5454D41D 11.34%, #5454D445 27%, #19191B);
    background-color: #19191B;
}

.main-section{
    width: 100%;
    display: flex;
    padding: 40px;

}
.main-box1{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding: 15px;
    gap: 1rem;
}

.main-box1  > h2{
    font-size: 70px;
    height: 28%;
    line-height: 81px;
    font-weight: 700;
    color: #FFFFFFF2;
}
.main-box1  > p{
    font-size: 18px;
    line-height: 35px;
    font-weight: 400;
    color: #FFFFFFF2;
}

.btn{
    font-size: 15px;
    line-height: 35px;
    background: linear-gradient(180deg,#4D6DEE ,#19191B) ;
    font-weight: 600;
    color: #FFFFFFF2;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background: linear-gradient(180deg, rgba(76, 113, 242, 0.88) 5%, rgba(79, 8, 122, 0.47) 100%);
    backdrop-filter: blur(1px);
    width: 143px;
    height: 44px;
    border-radius: 16px;
}



.main-box2{
    width: 50%;
    display: flex;
    cursor: pointer;
}

.main-box2 > img{
   width: 100%;
   height: 100%;
}



/* responsive design */

/* Event large */
@media screen and (min-width: 1201px) and (max-width: 1344px) {
    main{
        width: 100vw;
        height: 100vh;
        background: linear-gradient(45deg ,#5454D41D 11.34%, #5454D445 27%, #19191B);
        background-color: #19191B;
    }
    
    .main-section{
        width: 100%;
        display: flex;
        /* flex-direction: column; */
        padding: 40px;
    }
    .main-box1{
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        padding: 15px;
        gap: 1rem;
    }
    
    .main-box1  > h2{
        font-size: 50px;
        height: 40%;
        line-height: 71px;
        font-weight: 700;
        color: #FFFFFFF2;
        display: flex;
        align-items: center;

        /* background-color: rebeccapurple; */
    }
    .main-box1  > p{
        font-size: 17px;
        line-height: 35px;
        font-weight: 400;
        color: #FFFFFFF2;
        /* background-color: rebeccapurple; */
    }
    
    .btn{
        font-size: 15px;
        line-height: 35px;
        background: linear-gradient(180deg,#4D6DEE ,#19191B) ;
        font-weight: 600;
        color: #FFFFFFF2;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        background: linear-gradient(180deg, rgba(76, 113, 242, 0.88) 5%, rgba(79, 8, 122, 0.47) 100%);
        backdrop-filter: blur(1px);
        width: 143px;
        height: 44px;
        border-radius: 16px;
    }
    
    
    
    .main-box2{
        width: 40%;
        display: flex;
        cursor: pointer;
    }
    
    .main-box2 > img{
       width: 100%;
       height: 100%;
    }
}



/* For Desktops, large screens */
@media screen and (min-width: 1025px) and (max-width: 1200px) {
    main{
        width: 100vw;
        height: 100vh;
        background: linear-gradient(45deg ,#5454D41D 11.34%, #5454D445 27%, #19191B);
        background-color: #19191B;
    }
    
    .main-section{
        width: 100%;
        display: flex;
        /* flex-direction: column; */
        padding: 40px;
    }
    .main-box1{
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        padding: 15px;
        gap: 1rem;
    }
    
    .main-box1  > h2{
        font-size: 50px;
        height: 40%;
        line-height: 71px;
        font-weight: 700;
        color: #FFFFFFF2;
        /* background-color: rebeccapurple; */
    }
    .main-box1  > p{
        font-size: 17px;
        line-height: 35px;
        font-weight: 400;
        color: #FFFFFFF2;
    }
    
    .btn{
        font-size: 15px;
        line-height: 35px;
        background: linear-gradient(180deg,#4D6DEE ,#19191B) ;
        font-weight: 600;
        color: #FFFFFFF2;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        background: linear-gradient(180deg, rgba(76, 113, 242, 0.88) 5%, rgba(79, 8, 122, 0.47) 100%);
        backdrop-filter: blur(1px);
        width: 143px;
        height: 44px;
        border-radius: 16px;
    }
    
    
    
    .main-box2{
        width: 50%;
        display: flex;
        cursor: pointer;
    }
    
    .main-box2 > img{
       width: 100%;
       height: 100%;
    }
  }



  /* For Small screens , laptops */
@media screen and (min-width: 769px) and (max-width: 1024px) {
    main{
        width: 100vw;
        height: 100vh;
        background: linear-gradient(45deg ,#5454D41D 11.34%, #5454D445 27%, #19191B);
        background-color: #19191B;
    }
    
    .main-section{
        width: 100%;
        height: 55vh;
        display: flex;
        /* flex-direction: column; */
    }
    .main-box1{
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        gap: 1rem;
    }
    
    .main-box1  > h2{
        font-size: 38px;
        height: 25%;
        line-height: 45px;
        font-weight: 700;
        color: #FFFFFFF2;
        /* background-color: rebeccapurple; */

    }
    .main-box1  > p{
        font-size: 15px;
        line-height: 25px;
        font-weight: 400;
        height: 40%;
        color: #FFFFFFF2;
        /* background-color: red; */
    }
    
    .btn{
        font-size: 15px;
        line-height: 35px;
        background: linear-gradient(180deg,#4D6DEE ,#19191B) ;
        font-weight: 600;
        color: #FFFFFFF2;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        background: linear-gradient(180deg, rgba(76, 113, 242, 0.88) 5%, rgba(79, 8, 122, 0.47) 100%);
        backdrop-filter: blur(1px);
        width: 143px;
        height: 44px;
        border-radius: 16px;
        margin-bottom: 20px;
    }
    
    
    
    .main-box2{
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    
    .main-box2 > img{
       width: 80%;
       height: 60%;
    }
}


/* For Small screens , laptops */
@media screen and (min-width: 481px) and (max-width: 769px) {
    main{
        width: 100vw;
        height: 100vh;
        background: linear-gradient(45deg ,#5454D41D 11.34%, #5454D445 27%, #19191B);
        background-color: #19191B;
    }
    
    .main-section{
        width: 100%;
        height: 55vh;
        display: flex;
        /* flex-direction: column; */
    }
    .main-box1{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        gap: 1rem;
    }
    
    .main-box1  > h2{
        font-size: 38px;
        height: 29%;
        line-height: 35px;
        font-weight: 700;
        color: #FFFFFFF2;
        /* background-color: rebeccapurple; */

    }
    .main-box1  > p{
        font-size: 15px;
        line-height: 25px;
        font-weight: 400;
        height: 30%;
        color: #FFFFFFF2;
        /* background-color: red; */
    }
    
    .btn{
        font-size: 15px;
        line-height: 35px;
        background: linear-gradient(180deg,#4D6DEE ,#19191B) ;
        font-weight: 600;
        color: #FFFFFFF2;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        background: linear-gradient(180deg, rgba(76, 113, 242, 0.88) 5%, rgba(79, 8, 122, 0.47) 100%);
        backdrop-filter: blur(1px);
        width: 143px;
        height: 44px;
        border-radius: 16px;
        margin-bottom: 20px;
    }
    
    
    
    .main-box2{
        display: none;
       
    }
    
}


/*  Mobile devices */
@media screen and (min-width: 320px) and (max-width: 480px) {
    main{
        width: 100vw;
        height: 100vh;
        background: linear-gradient(45deg ,#5454D41D 11.34%, #5454D445 27%, #19191B);
        background-color: #19191B;
    }
    
    .main-section{
        width: 100%;
        height: 55vh;
        display: flex;
        /* flex-direction: column; */
    }
    .main-box1{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        gap: 0rem;
        padding: 0px;
    }
    
    .main-box1  > h2{
        font-size: 25px;
        height: 25%;
        line-height: 30px;
        font-weight: 700;
        color: #FFFFFFF2;
        display: flex;
        align-items: center;
        /* background-color: rebeccapurple; */

    }
    .main-box1  > p{
        font-size: 15px;
        line-height: 25px;
        font-weight: 300;
        height: 55%;
        color: #FFFFFFF2;
        /* background-color: red; */
        display: flex;
        /* align-items: center; */
    }
    
    .btn{
        font-size: 12px;
        line-height: 35px;
        background: linear-gradient(180deg,#4D6DEE ,#19191B) ;
        font-weight: 600;
        color: #FFFFFFF2;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        background: linear-gradient(180deg, rgba(76, 113, 242, 0.88) 5%, rgba(79, 8, 122, 0.47) 100%);
        backdrop-filter: blur(1px);
        width: 103px;
        height: 40px;
        border-radius: 16px;
        margin-bottom: 20px;
    }
    
    
    
    .main-box2{
        display: none;
       
    }
}