@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&family=Qwitcher+Grypen:wght@700&family=Roboto:ital,wght@0,100;1,100;1,300;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;1,6..12,200;1,6..12,300;1,6..12,400;1,6..12,500;1,6..12,600;1,6..12,700&family=Qwitcher+Grypen:wght@700&family=Roboto:ital,wght@0,100;1,100;1,300;1,700&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.navbar{
    display: flex;
    width: 100vw;
    height: 9vh;
    color: #ffff;
    /* background-color: red; */
    /* background-image:linear-gradient(20deg,#FFFFFF,#4D6DEE,#D9D9D9,#E8C9FF) ; */
   
}

.logoContainer{
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: black; */
}

.logoContainer > img{
    width: 30px;
    margin-right:10px ;
}
.logoContainer > h2{
/* font-size: 28px; */
font-family: 'Montserrat';
font-weight: bold;

}


.navbar-items-container{
    width: 65%;
    display: flex;
    align-items: center;
    gap: 3rem;
    /* background-color: black; */
   
}

.navbar-items-container > a{
    text-decoration: none;
    font-family: 'Nunito Sans', sans-serif;
    color: #ffff;
    font-weight: 900;
    width: 10%;
    /* background-color: rebeccapurple; */

}
.navbar-items-container > button{
   background: transparent;
   font-weight: 900;
   border-radius: 2px;
   color: #ffff;
    width: 10%;
    height: 50%;
    border: 1.5px solid;
    border-image: linear-gradient(50deg, #4D6DEE, #8218D3) 1 stretch;
    /* border-radius: 40px;  pending */
}
.navbar-items-container > button > a{
  text-decoration: none;
  color: #FFFFFF;
  cursor: pointer;
}

.navbar-items-container > .nav-btn{
    border: none;
    width: 50px;
    height: 50px;
    background-color: none;
    display: none;
}

nav > .nav-btn{
    border: none;
    width: 50px;
    height: 50px;
    color: #FFFFFF;
    background-color: transparent;
    display: none;  
}

/* Responsive Design */
@media only screen and (max-width: 1024px){
    .navbar{
        display: flex;
        align-items: center;
        /* justify-content: ; */
        /* width: 100vw; */
        /* height: 9vh; */
        color: #ffff;
        justify-content: space-between;
        /* background-color: red; */
        /* background-image:linear-gradient(20deg,#FFFFFF,#4D6DEE,#D9D9D9,#E8C9FF) ; */
       
    }
    .navbar-items-container > .nav-btn{
        display: block;
    }
    nav > .nav-btn{
        display: block;
    }
    .navbar-items-container{
     position: fixed;
     /* top: 0;
     left: 0; */
     height: 100%;
     width: 100%;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     gap: 5rem;
     transition: 0.1s;
     transform: translateY(-100vh);
     background: linear-gradient(45deg ,#5454D41D 11.34%, #5454D445 27%, #19191B);
     background-color: #19191B;
     z-index: 1;
    }

    .close-btn{
        position: absolute;
        top: 2rem;
        right: 2rem;
    }

    .navbar-items-container > button{
        background: transparent;
        font-weight: 900;
        border-radius: 2px;
        color: #ffff;
         /* width: 10%; */
         height: 44px;
         border: 1.5px solid;
         border-image: linear-gradient(50deg, #4D6DEE, #8218D3) 1 stretch;
         /* border-radius: 40px;  pending */
     }
    
     .responsive_nav{
        transform: none;
     }  
}

@media screen and (min-width: 320px) and (max-width: 1023px)  {
    .navbar{
        display: flex;
        align-items: center;
        color: #ffff;
        justify-content: space-between;
       
    }
    .navbar-items-container > .nav-btn{
        display: block;
    }
    nav > .nav-btn{
        display: block;
        /* color: red; */
        margin-right:20px ;
    }
    .navbar-items-container{
     position: fixed;
     top: 0;
     left: 0;
     height: 100%;
     width: 100%;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     gap: 5rem;
     transition: 0.1s;
     transform: translateY(-100vh);
     background: linear-gradient(45deg ,#5454D41D 11.34%, #5454D445 27%, #19191B);
     background-color: #19191B;
     z-index: 1;
    }
    .navbar-items-container > a{
      
        font-weight: 900;
        width: 100%;
        text-align: center;
        /* background-color: rebeccapurple; */
    
    }

    .close-btn{
        position: absolute;
        top: 2rem;
        right: 2rem;
    }

    .navbar-items-container > button{
        background: transparent;
        font-weight: 900;
        border-radius: 2px;
        color: #ffff;
         /* width: 10%; */
         height: 44px;
         border: 1.5px solid;
         border-image: linear-gradient(50deg, #4D6DEE, #8218D3) 1 stretch;
         /* border-radius: 40px;  pending */
     }
    
     .responsive_nav{
        transform: none;
     }
    .logoContainer > img{
        width: 25px;
        margin-right:10px;
        margin-left:40px;
    }
    .logoContainer{
        /* background-color: red; */
        display: flex;
        justify-content: start;
        width: 50%;

    }
    .navbar-items-container > button{
        background: transparent;
        width: 30%;
        font-weight: 900;
        border-radius: 2px;
        color: #ffff;
         /* width: 10%; */
         height: 44px;
         border: 1.5px solid;
         border-image: linear-gradient(50deg, #4D6DEE, #8218D3) 1 stretch;
         /* border-radius: 40px;  pending */
     }
}
