

.serviceBox{
    width: 22%;
    height: 45%;
    /* background: rgba(187, 158, 240, 0.11); */
    background: #23222c;
    border: 1px solid rgba(75, 12, 192, 0.56);
    box-shadow: 0px 34px 65px rgba(64, 85, 109, 0.07), 0px 22.037px 38.0671px rgba(64, 85, 109, 0.0531481), 0px 13.0963px 20.7037px rgba(64, 85, 109, 0.0425185), 0px 6.8px 10.5625px rgba(64, 85, 109, 0.035), 0px 2.77037px 5.2963px rgba(64, 85, 109, 0.0274815), 0px 0.62963px 2.55787px rgba(64, 85, 109, 0.0168519);
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #FFFFFFF2;
    padding: 10px;
    padding-left: 20px ;
    padding-right: 20px ;
    gap: 1rem;
    
}

.serviceBox > img {
    width: 100px;
    height: 100px;
}

.serviceBox > h3 {
    font-size: 28px;
    font-weight: 600;
    line-height: 38px;
    text-align: center;
    width: 100%;
   
  
}

.serviceBox > p {
    font-size: 18px;
    font-weight: 300;
    line-height: 25px;
    text-align: center;
}

.serviceBox:hover{
        transform: scale(1.02); 
        -webkit-transform: scale(1.02); /* For older Webkit browsers */
}



/* Responsive Design */

/* Event large */
@media screen and (min-width: 1201px) and (max-width: 1344px) {
    .serviceBox{
        width: 28%;
        height: 30%;
        /* background: rgba(187, 158, 240, 0.11); */
        background: #23222c;
        border: 1px solid rgba(75, 12, 192, 0.56);
        box-shadow: 0px 34px 65px rgba(64, 85, 109, 0.07), 0px 22.037px 38.0671px rgba(64, 85, 109, 0.0531481), 0px 13.0963px 20.7037px rgba(64, 85, 109, 0.0425185), 0px 6.8px 10.5625px rgba(64, 85, 109, 0.035), 0px 2.77037px 5.2963px rgba(64, 85, 109, 0.0274815), 0px 0.62963px 2.55787px rgba(64, 85, 109, 0.0168519);
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #FFFFFFF2;
        /* padding: 10px; */
        /* padding-left: 20px ;
        padding-right: 20px ; */
        gap: 1rem;
        
    }
    
    .serviceBox > img {
        width: 60px;
        height: 60px;
    }
    
    .serviceBox > h3 {
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        text-align: center;
        width: 100%;
        height: 20%;
       /* background-color: red; */
      
    }
    
    .serviceBox > p {
        font-size: 19px;
        font-weight: 200;
        line-height: 25px;
        text-align: center;
    }
    
    .serviceBox:hover{
            transform: scale(1.02); 
            -webkit-transform: scale(1.02); /* For older Webkit browsers */
    }


}

/* For large screens , desktop */

@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .serviceBox{
        width: 25%;
        height: 40%;
        /* background: rgba(187, 158, 240, 0.11); */
        background: #23222c;
        border: 1px solid rgba(75, 12, 192, 0.56);
        box-shadow: 0px 34px 65px rgba(64, 85, 109, 0.07), 0px 22.037px 38.0671px rgba(64, 85, 109, 0.0531481), 0px 13.0963px 20.7037px rgba(64, 85, 109, 0.0425185), 0px 6.8px 10.5625px rgba(64, 85, 109, 0.035), 0px 2.77037px 5.2963px rgba(64, 85, 109, 0.0274815), 0px 0.62963px 2.55787px rgba(64, 85, 109, 0.0168519);
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #FFFFFFF2;
        /* padding: 10px; */
        /* padding-left: 20px ;
        padding-right: 20px ; */
        gap: 1rem;
        
    }
    
    .serviceBox > img {
        width: 60px;
        height: 60px;
    }
    
    .serviceBox > h3 {
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        text-align: center;
        width: 100%;
        height: 20%;
       /* background-color: red; */
      
    }
    
    .serviceBox > p {
        font-size: 19px;
        font-weight: 200;
        line-height: 25px;
        text-align: center;
    }
    
    .serviceBox:hover{
            transform: scale(1.02); 
            -webkit-transform: scale(1.02); /* For older Webkit browsers */
    }
}

/* For Small screens , laptops */
@media screen and (min-width: 769px) and (max-width: 1024px) {

    .serviceBox{
        width: 37%;
        height: 27%;
        /* background: rgba(187, 158, 240, 0.11); */
        background: #23222c;
        border: 1px solid rgba(75, 12, 192, 0.56);
        box-shadow: 0px 34px 65px rgba(64, 85, 109, 0.07), 0px 22.037px 38.0671px rgba(64, 85, 109, 0.0531481), 0px 13.0963px 20.7037px rgba(64, 85, 109, 0.0425185), 0px 6.8px 10.5625px rgba(64, 85, 109, 0.035), 0px 2.77037px 5.2963px rgba(64, 85, 109, 0.0274815), 0px 0.62963px 2.55787px rgba(64, 85, 109, 0.0168519);
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #FFFFFFF2;
        gap: 0rem;
        
    }
    
    .serviceBox > img {
        width: 50px;
        height: 50px;
    }
    
    .serviceBox > h3 {
        font-size: 18px;
        font-weight: 600;
        line-height: 30px;
        text-align: center;
        width: 100%;
        height: 25%;
       /* background-color: red; */
      
    }
    
    .serviceBox > p {
        font-size: 14px;
        font-weight: 300;
        line-height: 25px;
        text-align: center;
    }
    
    .serviceBox:hover{
            transform: scale(1.02); 
            -webkit-transform: scale(1.02); /* For older Webkit browsers */
    }
    
}




/* For iPads, Tablets  */
@media screen and (min-width: 481px) and (max-width: 769px) {
    .serviceBox{
        width: 50%;
        height: 20%;
        /* background: rgba(187, 158, 240, 0.11); */
        background: #23222c;
        border: 1px solid rgba(75, 12, 192, 0.56);
        box-shadow: 0px 34px 65px rgba(64, 85, 109, 0.07), 0px 22.037px 38.0671px rgba(64, 85, 109, 0.0531481), 0px 13.0963px 20.7037px rgba(64, 85, 109, 0.0425185), 0px 6.8px 10.5625px rgba(64, 85, 109, 0.035), 0px 2.77037px 5.2963px rgba(64, 85, 109, 0.0274815), 0px 0.62963px 2.55787px rgba(64, 85, 109, 0.0168519);
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #FFFFFFF2;
        gap: 1rem;
        
    }
    
    .serviceBox > img {
        width: 40px;
        height: 40px;
    }
    
    .serviceBox > h3 {
        font-size: 15px;
        font-weight: 600;
        line-height: 30px;
        text-align: center;
        width: 100%;
        /* height: 90%; */
        overflow: hidden;
       /* background-color: red; */
      
    }
    
    .serviceBox > p {
        display: none;
    }
}

/*  Mobile devices */
@media screen and (min-width: 320px) and (max-width: 480px) {
    .serviceBox{
        width: 70%;
        height: 20%;
        background: #23222c;
        border: 1px solid rgba(75, 12, 192, 0.56);
        box-shadow: 0px 34px 65px rgba(64, 85, 109, 0.07), 0px 22.037px 38.0671px rgba(64, 85, 109, 0.0531481), 0px 13.0963px 20.7037px rgba(64, 85, 109, 0.0425185), 0px 6.8px 10.5625px rgba(64, 85, 109, 0.035), 0px 2.77037px 5.2963px rgba(64, 85, 109, 0.0274815), 0px 0.62963px 2.55787px rgba(64, 85, 109, 0.0168519);
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #FFFFFFF2;
        gap: 1rem;
        /* background-color: red; */
        
    }
    
    .serviceBox > img {
        width: 40px;
        height: 40px;
    }
    
    .serviceBox > h3 {
        font-size: 15px;
        font-weight: 600;
        line-height: 30px;
        text-align: center;
        width: 100%;
        /* height: 90%; */
        overflow: hidden;
       /* background-color: red; */
      
    }
    
    .serviceBox > p {
        display: none;
    }
}