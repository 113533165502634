.blog{
    width: 100vw;
    height: 100vh;
    background: linear-gradient(45deg ,#5454D41D 11.34%, #5454D445 27%, #19191B);
    background-color: #19191B;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFFF2;
}

