#contact_box{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.gray_box{
    width: 90%;
    height: 90%;
    display: flex;
    background-color: #1f1f29;
    border: 1.5px solid #1f1a4a ;
    border-radius: 20px;
}

.box1{
   width: 70%;
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   padding: 30px;
   /* background-color: black; */
}
.box1 > h3 {
    height: 20%;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    color: #FFFFFFF2;
    /* background-color: rebeccapurple; */

}
.box1 > form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: 70%;
}
.box1 > form > div{
   display: flex;
   gap: 1rem ;
   height: 10%;
   color: #FFFFFFB2;
   /* justify-content: center; */
   align-items: center;
   /* background-color: rebeccapurple; */
}

.box1 > form >  :nth-child(1){
      gap: 2.1rem;
      /* background-color: rebeccapurple; */
}
.box1 > form >  :nth-child(2){
      gap: 2.1rem;
      /* background-color: rebeccapurple; */
}
.box1 > form >  :nth-child(3){
      gap: 3.1rem;
      /* background-color: rebeccapurple; */
}

.box1 > form > div > label {
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    color: #FFFFFFB2;

}
.box1 > form > div > input,textarea{
   width: 70%;
   border: none;
   background-color: transparent;
   outline: none;
   border: 1px solid #C2C2C2;
   padding: 2px;
   border-radius: 10px;
   /* background-color: gold; */
   height: 90%;
   color: #FFFFFFB2;
   /* text-align: center; */
   padding: 7px;
}

.btnHolder{
    display: flex;
    justify-content: center
}

.box1 > form >div > button{
    font-size: 15px;
    line-height: 35px;
    background: linear-gradient(180deg,#4D6DEE ,#19191B) ;
    font-weight: 600;
    color: #FFFFFFF2;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background: linear-gradient(180deg, rgba(76, 113, 242, 0.88) 5%, rgba(79, 8, 122, 0.47) 100%);
    backdrop-filter: blur(1px);
    width: 143px;
    height: 44px;
    border-radius: 16px;


}


.fullNameBox > input {
   background-color: red;
   width: 20px;
   }

.box1 > form > div > textarea{
    overflow: hidden; 
  resize: none;
/* background-color: rebeccapurple; */
   
}
.box1 > form > :nth-child(4){
    height: 30%;
    align-items: start;
}


.box2{
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 20px;
 }

.box2 > img {
    width: 100%;
    height: 100%;
}


/* Responsive Design */

/* Event large */
@media screen and (min-width: 1201px) and (max-width: 1344px) {
    #contact_box{
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
    .gray_box{
        width: 90%;
        height: 90%;
        display: flex;
        background-color: #1f1f29;
        border: 1.5px solid #1f1a4a ;
        border-radius: 20px;
    }
    
    .box1{
       width: 70%;
       height: 100%;
       display: flex;
       flex-direction: column;
       justify-content: center;
       padding: 30px;
       /* background-color: black; */
    }
    .box1 > h3 {
        height: 20%;
        font-size: 32px;
        font-weight: 700;
        line-height: 48px;
        color: #FFFFFFF2;
        /* background-color: rebeccapurple; */
    
    }
    .box1 > form {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        height: 70%;
    }
    .box1 > form > div{
       display: flex;
       gap: 1rem ;
       height: 10%;
       color: #FFFFFFB2;
       /* justify-content: center; */
       align-items: center;
       /* background-color: rebeccapurple; */
    }
    
    .box1 > form >  :nth-child(1){
          gap: 2.1rem;
          /* background-color: rebeccapurple; */
    }
    .box1 > form >  :nth-child(2){
          gap: 2.1rem;
          /* background-color: rebeccapurple; */
    }
    .box1 > form >  :nth-child(3){
          gap: 3.1rem;
          /* background-color: rebeccapurple; */
    }
    
    .box1 > form > div > label {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        color: #FFFFFFB2;
        height:100%;
        display: flex;
        /* align-items: center; */
        /* background-color: red; */
    
    }
    .box1 > form > div > input,textarea{
       width: 70%;
       border: none;
       background-color: transparent;
       outline: none;
       border: 1px solid #C2C2C2;
       padding: 2px;
       border-radius: 10px;
       /* background-color: gold; */
       height: 90%;
       color: #FFFFFFB2;
       /* text-align: center; */
       padding: 7px;
    }
    
    .btnHolder{
        display: flex;
        justify-content: center
    }
    
    .box1 > form >div > button{
        font-size: 15px;
        line-height: 35px;
        background: linear-gradient(180deg,#4D6DEE ,#19191B) ;
        font-weight: 600;
        color: #FFFFFFF2;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        background: linear-gradient(180deg, rgba(76, 113, 242, 0.88) 5%, rgba(79, 8, 122, 0.47) 100%);
        backdrop-filter: blur(1px);
        width: 143px;
        height: 44px;
        border-radius: 16px;
    }
    
    
    .fullNameBox > input {
       background-color: red;
       width: 20px;
       }
    
    .box1 > form > div > textarea{
      overflow: hidden; 
      resize: none;
    /* background-color: rebeccapurple; */
       
    }
    .box1 > form > :nth-child(4){
        height: 30%;
        align-items: start;
    }
    
    .box2{
        width: 30%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 20px;
     }
    
    .box2 > img {
        width: 100%;
        height: 100%;
    }
}


/* for Small screens, laptops  */
@media screen and (min-width: 769px) and (max-width: 1024px) {
    #contact_box{
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
    .gray_box{
        width: 90%;
        height: 90%;
        display: flex;
        background-color: #1f1f29;
        border: 1.5px solid #1f1a4a ;
        border-radius: 20px;
    }
    
    .box1{
       width: 100%;
       height: 100%;
       display: flex;
       flex-direction: column;
       justify-content: center;
       padding: 30px;
       /* background-color: black; */
    }
    .box1 > h3 {
        height: 20%;
        font-size: 32px;
        font-weight: 700;
        line-height: 48px;
        color: #FFFFFFF2;
        /* background-color: rebeccapurple; */
    
    }
    .box1 > form {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        height: 70%;
    }
    .box1 > form > div{
       display: flex;
       gap: 1rem ;
       height: 10%;
       color: #FFFFFFB2;
       /* justify-content: center; */
       align-items: center;
       /* background-color: rebeccapurple; */
    }
    
    .box1 > form >  :nth-child(1){
          gap: 2.1rem;
          /* background-color: rebeccapurple; */
    }
    .box1 > form >  :nth-child(2){
          gap: 2.1rem;
          /* background-color: rebeccapurple; */
    }
    .box1 > form >  :nth-child(3){
          gap: 3.1rem;
          /* background-color: rebeccapurple; */
    }
    
    .box1 > form > div > label {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        color: #FFFFFFB2;
    
    }
    .box1 > form > div > input,textarea{
       width: 70%;
       border: none;
       background-color: transparent;
       outline: none;
       border: 1px solid #C2C2C2;
       padding: 2px;
       border-radius: 10px;
       /* background-color: gold; */
       height: 90%;
       color: #FFFFFFB2;
       /* text-align: center; */
       padding: 7px;
    }
    
    .btnHolder{
        display: flex;
        justify-content: center
    }
    
    .box1 > form >div > button{
        font-size: 15px;
        line-height: 35px;
        background: linear-gradient(180deg,#4D6DEE ,#19191B) ;
        font-weight: 600;
        color: #FFFFFFF2;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        background: linear-gradient(180deg, rgba(76, 113, 242, 0.88) 5%, rgba(79, 8, 122, 0.47) 100%);
        backdrop-filter: blur(1px);
        width: 143px;
        height: 44px;
        border-radius: 16px;
    
    
    }
    
    
    .fullNameBox > input {
       background-color: red;
       width: 20px;
       }
    
    .box1 > form > div > textarea{
      overflow: hidden; 
      resize: none;
    /* background-color: rebeccapurple; */
       
    }
    .box1 > form > :nth-child(4){
        height: 30%;
        align-items: start;
    }
    
    
    .box2{
        display: none;
     }
}


/* For iPads, Tablets  */
@media screen and (min-width: 481px) and (max-width: 769px) {
    #contact_box{
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
    .gray_box{
        width: 90%;
        height: 90%;
        display: flex;
        background-color: #1f1f29;
        border: 1.5px solid #1f1a4a ;
        border-radius: 20px;
    }
    
    .box1{
       width: 100%;
       height: 100%;
       display: flex;
       flex-direction: column;
       justify-content: center;
       padding: 30px;
       /* background-color: black; */
    }
    .box1 > h3 {
        height: 20%;
        font-size: 25px;
        font-weight: 700;
        line-height: 48px;
        color: #FFFFFFF2;
        /* background-color: rebeccapurple; */
    
    }
    .box1 > form {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        height: 70%;
    }
    .box1 > form > div{
       display: flex;
       flex-direction: column;
       gap: 1rem ;
       height: 20%;
       color: #FFFFFFB2;
       /* justify-content: center; */
       align-items: center;
       /* background-color: rebeccapurple; */
    }
    
    .box1 > form >  :nth-child(1){
          gap: 0rem;
          /* background-color: rebeccapurple; */
    }
    .box1 > form >  :nth-child(2){
          gap: 0rem;
          /* background-color: rebeccapurple; */
    }
    .box1 > form >  :nth-child(3){
          gap: 0rem;
          /* background-color: rebeccapurple; */
    }
    .box1 > form >  :nth-child(4){
          gap: 0rem;
          /* background-color: rebeccapurple; */
    }
    
    .box1 > form > div > label {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        color: #FFFFFFB2;
        display: none;
    
    }
    .box1 > form > div > input,textarea{
       width: 100%;
       border: none;
       background-color: transparent;
       outline: none;
       border: 1px solid #C2C2C2;
       padding: 2px;
       border-radius: 10px;
       /* background-color: gold; */
       height: 90%;
       color: #FFFFFFB2;
       /* text-align: center; */
       padding: 7px;
      text-align: center;

    }
    
    .btnHolder{
        display: flex;
        justify-content: center
    }
    
    .box1 > form >div > button{
        font-size: 15px;
        line-height: 35px;
        background: linear-gradient(180deg,#4D6DEE ,#19191B) ;
        font-weight: 600;
        color: #FFFFFFF2;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        background: linear-gradient(180deg, rgba(76, 113, 242, 0.88) 5%, rgba(79, 8, 122, 0.47) 100%);
        backdrop-filter: blur(1px);
        width: 143px;
        height: 44px;
        border-radius: 16px; 
    }
    .box1 > form > div > textarea{
      overflow: hidden; 
      resize: none;
      display: flex;
    /* background-color: rebeccapurple; */
       
    }
    .box1 > form > :nth-child(4){
        height: 30%;
        align-items: center;
    }
    .box2{
        display: none;
     }
}


/*  Mobile devices */
@media screen and (min-width: 320px) and (max-width: 480px) {
    #contact_box{
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
    .gray_box{
        width: 100%;
        height: 100%;
        display: flex;
        background-color: #1f1f29;
        border: 1.5px solid #1f1a4a ;
        border-radius: 0px;
        padding: 0px;
    }
    
    .box1{
       width: 100%;
       height: 100%;
       display: flex;
       flex-direction: column;
       justify-content: center;
       padding: 10px;
       /* background-color: black; */
    }
    .box1 > h3 {
        height: 20%;
        font-size: 25px;
        font-weight: 700;
        line-height: 48px;
        color: #FFFFFFF2;
        /* background-color: rebeccapurple; */
    
    }
    .box1 > form {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        height: 70%;
    }
    .box1 > form > div{
       display: flex;
       flex-direction: column;
       gap: 1rem ;
       height: 20%;
       color: #FFFFFFB2;
       /* justify-content: center; */
       align-items: center;
       /* background-color: rebeccapurple; */
    }
    
    .box1 > form >  :nth-child(1){
          gap: 0rem;
          /* background-color: rebeccapurple; */
    }
    .box1 > form >  :nth-child(2){
          gap: 0rem;
          /* background-color: rebeccapurple; */
    }
    .box1 > form >  :nth-child(3){
          gap: 0rem;
          /* background-color: rebeccapurple; */
    }
    .box1 > form >  :nth-child(4){
          gap: 0rem;
          /* background-color: rebeccapurple; */
    }
    
    .box1 > form > div > label {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        color: #FFFFFFB2;
        display: none;
    
    }
    .box1 > form > div > input,textarea{
       width: 100%;
       border: none;
       background-color: transparent;
       outline: none;
       border: 1px solid #C2C2C2;
       padding: 2px;
       border-radius: 10px;
       /* background-color: gold; */
       height: 90%;
       color: #FFFFFFB2;
       /* text-align: center; */
       padding: 7px;
      text-align: center;
      touch-action: manipulation;

    }
    
    .btnHolder{
        display: flex;
        justify-content: center
    }
    
    .box1 > form >div > button{
        font-size: 15px;
        line-height: 35px;
        background: linear-gradient(180deg,#4D6DEE ,#19191B) ;
        font-weight: 600;
        color: #FFFFFFF2;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        background: linear-gradient(180deg, rgba(76, 113, 242, 0.88) 5%, rgba(79, 8, 122, 0.47) 100%);
        backdrop-filter: blur(1px);
        width: 143px;
        height: 44px;
        border-radius: 16px; 
    }
    .box1 > form > div > textarea{
      overflow: hidden; 
      resize: none;
      display: flex;
    /* background-color: rebeccapurple; */
       
    }
    .box1 > form > :nth-child(4){
        height: 30%;
        align-items: center;
    }
    .box2{
        display: none;
     }
}