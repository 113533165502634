.footer{
    width: 100vw;
    height: 50vh;
    display: flex;
    align-items: end;
}

.footer_cont_box{
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1f1f29;
    border: 1px solid #1f1a49;
    border-top-left-radius: 60px;
    border-top-right-radius: 60px;
}

.footer_box1{
    width: 30%;
    height: 100%; 
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.footer_box1 > h3 {
    height: 15%;
    font-size: 30px;
    font-weight: 600;
    line-height: 32px;
    color: #FFFFFFF2;
}

.footer_box1 > a{
    text-decoration: none;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    color: #FFFFFFF2;
    cursor: pointer;
}
.footer_box2{
    width: 70%;
    height: 100%; 
    display: flex;
    flex-direction: column;
    /* background-color: blue; */
    /* justify-content:center ; */
    /* gap: 2rem; */
}

.footer_box21{
    display: flex;
    width: 100%;
    height: 90%;
    /* background-color: green; */
    /* gap: 10rem; */
}

.footer_contactBox{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
}

.footer_contactBox > h3{
    height: 15%;
    font-size: 30px;
    font-weight: 600;
    line-height: 32px;
    color: #FFFFFFF2;

}

.footer_contactBox > p{
    color: #FFFFFFF2;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;

}

.footer_socialMedia{
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.4rem;
    color: #FFFFFFF2;
    
    /* background-color: aqua; */
}

.footer_socialMedia > h3{
    height: 15%;
    /* background-color: gray; */
    font-size: 30px;
    font-weight: 600;
    line-height: 32px;
    color: #FFFFFFF2;

}

.footer_box22{
    /* background-color: pink; */
    display: flex;
    justify-content: space-evenly;
}

.footer_box22 > p{
    color: #FFFFFFCC;
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
}
.footer_socialMedia > a {
    color: #FFFFFFF2;

}
/* Responsive Design */

/* Event large */
@media screen and (min-width: 1201px) and (max-width: 1344px) {
}

/* for Small screens, laptops  */
@media screen and (min-width: 770px) and (max-width: 1024px) {

        .footer{
            width: 100vw;
            height: 90vh;
            display: flex;
            align-items: start;
        }
        
        .footer_cont_box{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            background-color: #1f1f29;
            border: 1px solid #1f1a49;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            /* background-color: rebeccapurple; */
        }
        
        .footer_box1{
            width: 100%;
            height: 40%; 
            /* background-color: red; */
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1rem;
        }
        
        .footer_box1 > h3 {
            height: 15%;
            font-size: 20px;
            font-weight: 600;
            line-height: 32px;
            color: #FFFFFFF2;
        }
        
        .footer_box1 > a{
            text-decoration: none;
            font-size: 15px;
            font-weight: 400;
            line-height: 20px;
            color: #FFFFFFF2;
            cursor: pointer;
            margin-top: 10px;
        }
        .footer_box2{
            width: 100%;
            height: 50%; 
            display: flex;
            flex-direction: column;
            /* background-color: blue; */
            /* justify-content:center ; */
            /* gap: 2rem; */
        }
        
        .footer_box21{
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 85%;
        }
        
        .footer_contactBox{
            width: 100%;
            height: 90%;
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: center;
            gap: 1rem;
            /* background-color: rebeccapurple; */
        }
        
        .footer_contactBox > h3{
            height: 20%;
            width: 100%;
            text-align: center;
            /* background-color: red; */
            font-size: 20px;
            font-weight: 600;
            line-height: 32px;
            color: #FFFFFFF2;
        }
        
        .footer_contactBox > p{
            color: #FFFFFFF2;
            font-size: 15px;
            font-weight: 400;
            line-height: 18px;
        }
        
        .footer_socialMedia{
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 1.4rem;
            color: #FFFFFFF2;
            /* background-color: aqua; */
        }
        .footer_socialMedia > a {
            color: #FFFFFFF2;
    
        }
        .footer_socialMedia > h3{
            height: 24%;
            /* background-color: gray; */
            font-size: 20px;
            font-weight: 600;
            line-height: 32px;
            margin-top: 15px;
            color: #FFFFFFF2;
        
        }
        
        .footer_box22{
            /* background-color: pink; */
            display: flex;
            /* justify-content: ; */
        }
        
        .footer_box22 > p{
            color: #FFFFFFCC;
            font-weight: 500;
            font-size: 10px;
            line-height: 25px;
        }
}

/* For iPads, Tablets  */
@media screen and (min-width: 481px) and (max-width: 769px) {
    .footer{
        width: 100vw;
        height: 90vh;
        display: flex;
        align-items: start;
    }
    
    .footer_cont_box{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background-color: #1f1f29;
        border: 1px solid #1f1a49;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        /* background-color: rebeccapurple; */
    }
    
    .footer_box1{
        width: 100%;
        height: 40%; 
        /* background-color: red; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }
    
    .footer_box1 > h3 {
        height: 15%;
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        color: #FFFFFFF2;
    }
    
    .footer_box1 > a{
        text-decoration: none;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        color: #FFFFFFF2;
        cursor: pointer;
        margin-top: 10px;
    }
    .footer_box2{
        width: 100%;
        height: 50%; 
        display: flex;
        flex-direction: column;
        /* background-color: blue; */
        /* justify-content:center ; */
        /* gap: 2rem; */
    }
    
    .footer_box21{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 85%;
    }
    
    .footer_contactBox{
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        gap: 1rem;
        /* background-color: rebeccapurple; */
    }
    
    .footer_contactBox > h3{
        height: 20%;
        width: 100%;
        text-align: center;
        /* background-color: red; */
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        color: #FFFFFFF2;
    }
    
    .footer_contactBox > p{
        color: #FFFFFFF2;
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
    }
    
    .footer_socialMedia{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 1.4rem;
        color: #FFFFFFF2;
        /* background-color: aqua; */
    }
    .footer_socialMedia > a {
        color: #FFFFFFF2;

    }
    .footer_socialMedia > h3{
        height: 24%;
        /* background-color: gray; */
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        margin-top: 15px;
        color: #FFFFFFF2;
    
    }
    
    .footer_box22{
        /* background-color: pink; */
        display: flex;
        /* justify-content: ; */
    }
    
    .footer_box22 > p{
        color: #FFFFFFCC;
        font-weight: 500;
        font-size: 10px;
        line-height: 25px;
    }
}

/*  Mobile devices */
@media screen and (min-width: 320px) and (max-width: 480px) {
    .footer{
        display: none;
    }
}